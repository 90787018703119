import {
  addMemberToChats,
  addMessagesToChat,
  addMoreChats,
  addNewChatToCurrentArrayOfChats,
  addNewMessageToChats,
  deleteChat,
} from '../../helpers/customFunctions';

import {
  // RELOAD_PAGE_CHAT,
  ACTIVE_USER,
  ADD_GROUPS_MEMBERS_RESPONSE,
  ADD_GROUPS_MEMBERS,
  ADD_LOGGED_USER,
  CHAT_ERROR,
  CHAT_USER,
  CLEAR_MESSAGES_CHAT,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT,
  FULL_USER,
  GET_CHAT_DATA_ON_LOGIN,
  GET_CHAT_DATA_ON_RELOAD,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST,
  GET_MESSAGES_BY_CHAT_SUCCESS,
  GET_MESSAGES_BY_CHAT,
  OPEN_SOCKET_CONNECTION,
  LOAD_MORE_CHATS_SUCCESS,
  LOAD_MORE_CHATS,
  LOAD_MORE_MESSAGES,
  LOAD_MORE_MESSAGES_SUCCESS,
  NEW_MESSAGE_INCOMING,
  FINDING_NEW_MESSAGE_CHAT,
  NEW_MESSAGE_SUCCESS,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE,
  START_BROADCAST_RESPONSE,
  START_BROADCAST,
} from './constants';

//Import Images
import logo from '../../assets/images/z2b2-logo.png';

const INIT_STATE = {
  loading: false,
  active_user: 0,
  users: [
    //admin is sender and user in receiver
    {
      id: 0,
      name: 'Team Z2B2',
      profilePicture: logo,
      lastUpdated: 'Tue, 28 Sep 2021 18:42:43 GMT',
      status: 'online',
      unRead: 0,
      roomType: 'contact',
      isGroup: false,
      messages: [
        {
          id: 1,
          text: "Hello user, welcome to Z2B2 chat, here you can reach customers around the world to promote and sell your jewell products, isn't awesome?",
          time: 'Tue, 28 Sep 2021 18:42:43 GMT',
          userType: 'receiver',
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 1,
          text: "Since this is an automated message, you can't response to this chat, if need help please contact support.",
          time: 'Tue, 28 Sep 2021 18:42:43 GMT',
          userType: 'receiver',
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
  ],
  groups: [
    // {
    //   groupId: 1,
    //   name: '#General',
    //   profilePicture: 'Null',
    //   isGroup: true,
    //   unRead: 0,
    //   desc: 'General Group',
    //   members: [
    //     { userId: 1, name: 'Sara Muller', profilePicture: 'Null', role: null },
    //     {
    //       userId: 2,
    //       name: 'Ossie Wilson',
    //       profilePicture: avatar8,
    //       role: 'admin',
    //     },
    //     {
    //       userId: 3,
    //       name: 'Jonathan Miller',
    //       profilePicture: 'Null',
    //       role: null,
    //     },
    //     { userId: 4, name: 'Paul Haynes', profilePicture: avatar7, role: null },
    //     { userId: 5, name: 'Yana sha', profilePicture: avatar3, role: null },
    //     {
    //       userId: 6,
    //       name: 'Steve Walker',
    //       profilePicture: avatar6,
    //       role: null,
    //     },
    //   ],
    // },
  ],
  contacts: [
    // { id: 1, name: 'Albert Rodarte' },
  ],
  duplicateContacts: [],
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHAT_DATA_ON_LOGIN:
      const usersOnLogin = !action.payload.users.length ? state.users : action.payload.users;
      return {
        ...state,
        loading: false,
        users: usersOnLogin,
        contacts: action.payload.contacts,
        groups: action.payload.groups,
        chatSelected: false,
      };

    // case RELOAD_PAGE_CHAT:
    //   return { ...state, loading: true };
    case GET_CHAT_DATA_ON_RELOAD:
      const usersOnReload = !action.payload.users.length ? state.users : action.payload.users;
      return {
        ...state,
        loading: false,
        users: usersOnReload,
        contacts: action.payload.contacts,
        groups: action.payload.groups,
        chatSelected: false,
      };

    case CHAT_USER:
      return { ...state };

    case CHAT_ERROR:
      return { ...state, loading: false, error: action.payload, success: null, chatSelected: true };
    case CLEAR_MESSAGES_CHAT:
      delete state.error;
      delete state.success;
      return {
        ...state,
        loading: false,
      };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload,
        chatSelected: true,
      };

    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      };

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };

    case CREATE_GROUP:
      return { ...state, loading: true };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.payload.groups,
        users: action.payload.users,
        contacts: action.payload.contacts,
        success: action.payload.success,
      };

    case START_BROADCAST:
      return { ...state, loading: true };
    case START_BROADCAST_RESPONSE:
      return { ...state, loading: false, success: action.payload };

    case DELETE_CHAT:
      return { ...state, loading: true };
    case DELETE_CHAT_SUCCESS:
      // call deleteChat function and passed chatId from payload, and a copy of chats array
      const deletedChat = deleteChat(action.payload.chatId, Object.assign([], state.users));
      return { ...state, loading: false, success: action.payload.message, users: deletedChat };

    case OPEN_SOCKET_CONNECTION:
      return { ...state, loading: false };

    case GET_MESSAGES_BY_CHAT:
      return { ...state, loading: true };
    case GET_MESSAGES_BY_CHAT_SUCCESS:
      // console.log(action.payload);
      // if (action.payload.chatSelected.messages.length > 0) {
      const { chatSelected, index } = action.payload;
      
      const addMessages = addNewChatToCurrentArrayOfChats({
        newChat: chatSelected[index],
        index,
        chats: Object.assign([], state.users),
      });

      return {
        ...state,
        loading: false,
        users: addMessages.sorted,
        active_user: addMessages.newIndex,
        chatSelected: true,
      };
    // } else if (action.payload.isGroup) {
    //   return { ...state, loading: false, chatSelected: action.payload.isGroup };
    // } else {
    //   return { ...state, loading: false };
    // }
    case LOAD_MORE_MESSAGES:
      return { ...state, loading: true };
    case LOAD_MORE_MESSAGES_SUCCESS:
      const { active_user, users} = action.payload;
      return {
        ...state,
        loading: false,
        users,
        active_user,
        chatSelected: true,
      };

    case LOAD_MORE_CHATS:
      return { ...state, loading: true };
    case LOAD_MORE_CHATS_SUCCESS:
      if (action.payload.length > 0) {
        const addChats = addMoreChats(action.payload, Object.assign([], state.users));
        return {
          ...state,
          loading: false,
          users: addChats,
        };
      } else {
        return { ...state, loading: false };
      }

    case SEND_MESSAGE:
      return { ...state, loading: true };
    case SEND_MESSAGE_SUCCESS:
      // call addNewMessageToChats function and send the message (action.payload) and a freezed copy of the actual state
      const sendMessageState = addNewMessageToChats(action.payload, Object.assign([], state.users)); //return the new state and index of active user
      return {
        ...state,
        loading: false,
        users: sendMessageState.sorted,
        active_user: sendMessageState.newIndex,
      };

    case NEW_MESSAGE_INCOMING:
      return {
        ...state,
        loading: true,
        incomingMessage: action.payload,
      };
    case FINDING_NEW_MESSAGE_CHAT:
      delete state.incomingMessage;
      return {
        ...state,
        loading: true,
      };
    case NEW_MESSAGE_SUCCESS:
      const newMessageAdded = addNewChatToCurrentArrayOfChats({
        newChat: action.payload.newChat,
        index: action.payload.index,
        chats: Object.assign([], state.users),
      }); //return the new state and index of active user
      return {
        ...state,
        loading: false,
        users: newMessageAdded.sorted,
        active_user: newMessageAdded.newIndex,
      };

    //GET MEMBERS
    case GET_MEMBERS_LIST:
      return { ...state, loading: true };
    case GET_MEMBERS_LIST_SUCCESS:
      const addMember = addMemberToChats(action.payload.members, state.users);
      return { ...state, loading: false, users: addMember };

    case ADD_GROUPS_MEMBERS:
      return { ...state, loading: true };

    case ADD_GROUPS_MEMBERS_RESPONSE:
      const { group, members, success, page } = action.payload;
      console.log(group);
      console.log(members);
      console.log(page);
      const addnewMemberChat = addMemberToChats( {chatId: group.chatId, members, page}, state.users);

      return { 
        ...state, 
        loading: false, 
        users: addnewMemberChat,
        success,
      };

    default:
      return { ...state };
  }
};

export default Chat;
