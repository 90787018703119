import { all, call, fork, put, takeEvery, delay, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { APIClient } from '../../helpers/apiClient';
import Cookies from 'universal-cookie';
import {
  // sortedByDate,
  addMessagesToChat,
  findChatAndIndex,
  getAuthData,
  getGroupsAndSortedChats,
  getUsersAndContacts,
  loadMessages,
  addMessageOrRetrieveChat,
} from '../../helpers/customFunctions';
import * as signalR from '@microsoft/signalr';
import moment from 'moment';

// import constants from chat redux
import {
  ADD_GROUPS_MEMBERS,
  ADD_GROUPS_MEMBERS_RESPONSE,
  CREATE_GROUP,
  DELETE_CHAT,
  FINDING_NEW_MESSAGE_CHAT,
  GET_MEMBERS_LIST,
  GET_MESSAGES_BY_CHAT,
  OPEN_SOCKET_CONNECTION,
  LOAD_MORE_CHATS,
  LOAD_MORE_MESSAGES,
  SEND_MESSAGE,
  START_BROADCAST,
} from './constants';

// get actions from chat redux
import {
  // getDataOnReloadSuccess,
  chatError,
  createGroupSuccess,
  deleteChatSuccess,
  getMembersListSuccess,
  getMessagesByChatSuccess,
  loadMoreChatsSuccess,
  loadMoreMessagesSuccess,
  newMessageIncoming,
  newMessageSuccess,
  sendMessageSuccess,
  startBroadcastResponse,
  addGroupMembersResponse,
} from './actions';

const cookies = new Cookies();
const update = new APIClient().put;
const get = new APIClient().get;
const post = new APIClient().post;
const postForm = new APIClient().postForm;
const remove = new APIClient().delete;
const putForm = new APIClient().putForm;

/**
 * Custom functions to reuse
 * @param {*} payload - any data needed
 */
// const loadMessages = async ({ chatId, token, take = 20, skip = 0 }) => {
//   // console.log({ take, skip });
//   const requestMessages = await get({
//     url: `/Chat/GetMessages?chatId=${chatId}&take=${take}&skip=${skip}`,
//     token,
//   });
//   // console.log(requestMessages.json);
//   return requestMessages;
// };

/**
 * Create new group
 * @param {*} payload - name, file and description(?)
 */
function* createGroup({ payload: { values, members, addMembers } }) {
  try {
    console.log({ values, members, addMembers });
    const auth = getAuthData();
    const { token, mid } = auth;

    const { title, description } = values;
    let createGroupResponse = null;

    console.log(members);

    if (addMembers.option === 'addedManually') {
      const data = {
        name: title,
        description,
        members,
        merchantId: mid,
      };
      console.log('data: ', data);

      createGroupResponse = yield call(post, { url: `/Group/PostGroupContacts`, data, token });
    } else if (addMembers.option === 'addedByFile') {
      // console.log('enviare un form data');

      const data = new FormData();

      data.append('name', title);
      data.append('description', description);
      data.append('file', members);
      data.append('merchantId', mid);

      // console.log('merchantID: ', mid);
      // console.log('data values');
      // for (let pair of data.entries()) {
      //   console.log(pair[0] + ': ' + pair[1]);
      // }

      createGroupResponse = yield call(postForm, { url: '/Group/Post', data, token });
    }

    console.log('response create', createGroupResponse);

    if (createGroupResponse.response.status === 200) {
      console.log('group created successfully');
      const usersAndContacts = yield getUsersAndContacts({ mid, token });
      const chats = yield getGroupsAndSortedChats(usersAndContacts.users);
      yield put(
        createGroupSuccess({
          groups: chats.groups,
          users: chats.sorted,
          contacts: usersAndContacts.contacts,
          success: 'Group created successfully.',
        })
      );
    } else if (createGroupResponse.response.status === 400) {
      yield put(chatError('Already exists another group with that name.'));
    } else if (createGroupResponse.response.status === 406) {
      yield put(chatError('File problem: Verify if data is correct.'));
    } else {
      yield put(chatError('There was an error in our end, please try again later.'));
    }
  } catch (error) {
    console.error('error on create group saga: ', error);
  }
}

/**
 * Delete Group
 * @param {*} payload - { chatId, isGroup, merchantId }
 */
function* deleteChat({ payload: { chatId, isGroup } }) {
  try {
    // console.log({ chatId, isGroup});
    const auth = getAuthData();
    const { token } = auth;
    let deleteChatRequest = null;
    if (isGroup) {
      // console.log('a group will be deleted');
      deleteChatRequest = yield call(remove, { url: `/Group/Delete?groupId=${chatId}`, token });
    } else {
      // console.log('a single chat will be deleted');
      deleteChatRequest = yield call(remove, { url: `/Chat/Delete?chatId=${chatId}`, token });
    }
    console.log('delete: ', deleteChatRequest.response.status);
    if (deleteChatRequest.response.status === 200) {
      yield put(deleteChatSuccess({ message: 'Chat removed successfully.', chatId }));
    } else {
      yield put(chatError('There was an error deleting the chat.'));
    }
  } catch (error) {}
}

/**
 * Start new broadcast
 * @param {*} payload - name, groupId, description(?), file, channel
 */
function* startBroadcast({ payload: { values, channels } }) {
  try {
    console.log({ values, channels });
    const auth = getAuthData();
    const { token } = auth;
    const { productId, groups } = values;

    const formData = new FormData();

    formData.append('Email', channels[1].checked);
    formData.append('ProductId', productId);
    formData.append('Sms', channels[0].checked);
    formData.append('Time', Date());
    formData.append('WhatsApp', channels[2].checked);
    groups.forEach((group) => {
      formData.append('GroupsId[]', group);
    });

    // console.log('broadcast values to send');
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }

    const startBroadcast = yield call(postForm, {
      url: '/Group/StartBroadcast',
      data: formData,
      token,
    });
    // const responseArray = Object.values(startBroadcast);
    console.log('broadcast response: ', startBroadcast.response.status);

    if (startBroadcast.response.status === 200) {
      yield put(startBroadcastResponse('Broadcast sent successfully.'));
    } else {
      yield put(chatError('Ups, there was an error, please try again later.'));
    }
  } catch (error) {
    console.error('error on start broadcast: ', error);
  }
}

/**
 * Create socket connection to listen for new messages
 * @param {*} payload - token
 */
function* openSocketConnection({ payload: token }) {
  try {
    // console.log('getting socket connection...');
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_AZURE_URL}/ChatHub`, {
        accessTokenFactory: () => token,
      })
      .build();

    let attempt = 0;
    let connected = false;
    while (attempt < 10 && !connected) {
      attempt++;
      connected = true;
      try {
        yield call(() => connection.start());
        console.info('SignalR: successfully connected');
      } catch (err) {
        console.info(`SignalR: attempt ${attempt}: failed to connect`);
        yield call(delay, 1000);
        connected = false;
      }
    }

    if (connected) {
      const getEventChannel = (connection) =>
        eventChannel((emit) => {
          const handler = (data) => {
            emit(data);
          };
          connection.on('OnReceiveMessage', (notification) => {
            handler(notification);
          });
          return () => {
            connection.off();
          };
        });

      const channel = yield call(getEventChannel, connection);

      while (true) {
        const message = yield take(channel);
        try {
          if (message.merchantContactId !== null || message.merchantContactId !== undefined) {
            console.log('new message...');
            const {
              contact: { merchantContactId },
              messageRequest,
            } = message;
            yield put(newMessageIncoming({ merchantContactId, messageRequest }));
          }
        } catch (error) {}
      }
    }
  } catch (error) {
    console.log('error get message: ', error);
  }
}

/**
 * Send New Message
 * @param {*} payload - message to send
 * Message: {
 * id,
 * message,
 * time,
 * userType,
 * isImageMessage,
 * isFileMessage,
 * imageMessage: [{
 * image
 * }]
 */
function* sendMessage({ payload: { text, type, chatId, isGroup, users } }) {
  try {
    // console.log({ text, type, chatId, isGroup, users });
    const auth = getAuthData();
    const { token, mid } = auth;
    const time = moment().format();

    let messageRequest = null;
    switch (type) {
      case 'textMessage':
        messageRequest = {
          text,
          file: null,
          time,
          userType: 'sender',
          // image: avatar4,
          isFileMessage: false,
          isImageMessage: false,
        };
        break;

      case 'fileMessage':
        messageRequest = {
          text: null,//'file',
          file: text,
          // size: text.size,
          time,
          userType: 'sender',
          // image: avatar4,
          isImageMessage: false,
          isFileMessage: true,
          imageMessage: text,
        };
        break;

      case 'imageMessage':
        // const imageMessage = [{ image: text }];
        messageRequest = {
          text: null,//text !== 'image' ? text : 'image',
          file: text,
          // size: text.size,
          time,
          userType: 'sender',
          // image: avatar4,
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: text,
        };
        break;

      default:
        break;
    }

    const messageArray = Object.entries(messageRequest);
    const data = new FormData();
    messageArray.forEach((item) => {
      data.append(item[0], item[1]);
    });

    const sendMessage = yield call(postForm, {
      url: `/Chat/Post?merchantId=${mid}&chatId=${chatId}&isGroup=${isGroup}`,
      data,
      token,
    });

    if (sendMessage.response.status === 200) {
      // console.log('message sent!!!');
      const chatAndIndex = yield findChatAndIndex(users, chatId);
      const addOrRetrieve = yield addMessageOrRetrieveChat(chatAndIndex, messageRequest);
      yield put(newMessageSuccess(addOrRetrieve));
      // yield put(sendMessageSuccess({ messageRequest, merchantContactId: chatId }));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * find which chat does the incoming message correspond and update chats state
 * @param {*} payload - { users, incomingMessage }
 */
function* findingNewMessageChat({ payload: { users, incomingMessage } }) {
  try {
    // console.log({ users, incomingMessage });
    const { merchantContactId, messageRequest } = incomingMessage;
    const chatAndIndex = yield findChatAndIndex(users, merchantContactId);
    const addOrRetrieve = yield addMessageOrRetrieveChat(chatAndIndex, messageRequest);
    yield put(newMessageSuccess(addOrRetrieve));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get 20 messages from a single chat using its chatId
 * @param {*} payload Object - { chatId, skip }
 */
function* getMessagesByChat({ payload: { users, chatId, skip } }) {
  try {
    console.log('chat clicked');
    const auth = getAuthData();
    const { token } = auth;
    const requestMessages = yield loadMessages({ chatId, token, skip });
    if (requestMessages.response.status === 200) {
      const chatSelected = yield addMessagesToChat({
        messages: requestMessages.json,
        chatId,
        users,
      });
      // console.log(chatSelected);
      yield put(getMessagesByChatSuccess(chatSelected));
    } else {
      console.log('There was an error retrieving the messages from the server.');
      yield put(chatError('There was an error retrieving the messages from the server.'));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Load another 20 messages from a single chat using its chatId
 * @param {*} payload Object - { chatId, skip }
 */
function* loadMoreMessages({ payload: { chatId, skip, users } }) {
  try {
    const auth = getAuthData();
    const { token } = auth;
    const requestMessages = yield loadMessages({ chatId, token, skip });
    if (requestMessages.response.status === 200) {
      const addMessages = yield addMessagesToChat({
        messages: requestMessages.json,
        chatId,
        users,
      });
      // console.log(addMessages);
      yield put(loadMoreMessagesSuccess({ active_user: addMessages.index, users:addMessages.chatSelected }));
    } else {
      yield put(chatError('There was an error retrieving the messages from the server.'));
    }
  } catch (error) {}
}

/**
 * Load another 20 chats
 * @param {*} payload number Int - skip
 */
function* loadMoreChats({ payload: skip }) {
  try {
    const auth = getAuthData();
    const { token, mid } = auth;
    // console.log('skip: ', skip);
    const loadChatsResponse = yield getUsersAndContacts({ mid, token, take: 5, skip });
    // console.log(loadChatsResponse);
    yield put(loadMoreChatsSuccess(loadChatsResponse.users));
  } catch (error) {}
}

/**
 * Add members to an existing group.
 * @param {*} payload Object - {group, members, addMembers}
 */
function* addGroupMembers({ payload: { group, members, addMembers } }) {
  try {
    // console.log('add group members: ', { group, members, addMembers });
    const auth = getAuthData();
    const { token, mid } = auth;
    const { option } = addMembers;
    const { chatId } = group;
    let addMembersToExistingGroup = null;
    const page = group.members >= 20 ? 2 : 1 ;

    if (option === 'addedManually') {
      addMembersToExistingGroup = yield call(
        update,{url:`/Group/PutAddContacts?groupId=${chatId}`,data: members, token}
      );
    } else if (option === 'addedByFile') {
      const formData = new FormData();
      formData.append('file', members);
      formData.append('groupId', chatId);

      addMembersToExistingGroup = yield call(putForm, {url:'/Group/AddContacts', data: formData, token});
    }

    if (addMembersToExistingGroup.status === 200 || addMembersToExistingGroup.response.status === 200) {
      
      if (option === 'addedManually'){
        yield put(addGroupMembersResponse({
          group, members , success: 'New members added successfully.', page: 2,
        }));
      }
      else if (option === 'addedByFile') {
        const chatMembers = yield call(get, {
          url: `/Group/GetPaginateContactsGroup?groupId=${chatId}&page=${page}`,
          token,
        });

        yield put(addGroupMembersResponse({
          group, members: chatMembers.json , success: 'New members added successfully.',page,
        }));
      }

    } else {
      yield put(chatError('There was an error adding new members.'));
    }
  } catch (error) {console.log("error ", error)}
}

/**
 * Add members to an existing group.
 * @param {*} payload Object - {group, members, addMembers}
 */
function* getMembersList({ payload: { chatId, page } }) {
  try {
    const auth = getAuthData();
    const { token } = auth;

    const chatMembers = yield call(get, {
      url: `/Group/GetPaginateContactsGroup?groupId=${chatId}&page=${page}`,
      token,
    });

    if (chatMembers.response.status === 200) {
      yield put(getMembersListSuccess({ members: chatMembers.json, chatId, page }));
    } else {
      yield put(chatError('There was an error gettting the group members.'));
    }
  } catch (error) {}
}

/**
 * Saga functions to watch reducers and execute functions
 */
export function* watchCreateGroup() {
  yield takeEvery(CREATE_GROUP, createGroup);
}
export function* watchStartBroadcast() {
  yield takeEvery(START_BROADCAST, startBroadcast);
}
export function* watchSendMessage() {
  yield takeEvery(SEND_MESSAGE, sendMessage);
}
export function* watchDeleteChat() {
  yield takeEvery(DELETE_CHAT, deleteChat);
}
export function* watchOpenSocketConnection() {
  yield takeEvery(OPEN_SOCKET_CONNECTION, openSocketConnection);
}
export function* watchAddGroupMembers() {
  yield takeEvery(ADD_GROUPS_MEMBERS, addGroupMembers);
}
export function* watchLoadMoreMessages() {
  yield takeEvery(LOAD_MORE_MESSAGES, loadMoreMessages);
}
export function* watchLoadMoreChats() {
  yield takeEvery(LOAD_MORE_CHATS, loadMoreChats);
}
export function* watchGetMembersList() {
  yield takeEvery(GET_MEMBERS_LIST, getMembersList);
}
export function* watchGetMessagesByChat() {
  yield takeEvery(GET_MESSAGES_BY_CHAT, getMessagesByChat);
}
export function* watchFindingNewMessageChat() {
  yield takeEvery(FINDING_NEW_MESSAGE_CHAT, findingNewMessageChat);
}

function* chatSaga() {
  // yield all([fork(watchGetDataOnReload)]);
  yield all([fork(watchCreateGroup)]);
  yield all([fork(watchStartBroadcast)]);
  yield all([fork(watchSendMessage)]);
  yield all([fork(watchDeleteChat)]);
  yield all([fork(watchOpenSocketConnection)]);
  yield all([fork(watchAddGroupMembers)]);
  yield all([fork(watchLoadMoreChats)]);
  yield all([fork(watchLoadMoreMessages)]);
  yield all([fork(watchGetMembersList)]);
  yield all([fork(watchGetMessagesByChat)]);
  yield all([fork(watchFindingNewMessageChat)]);
}

export default chatSaga;
