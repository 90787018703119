import {
  API_FAILED,
  AUTO_LOGIN_SUCCESS,
  AUTO_LOGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CLEAR_MESSAGES,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  RELOAD_PAGE_AUTH_SUCCESS,
  RELOAD_PAGE_AUTH,
  SUCCESS_MESSAGE,
  UPDATE_PICTURE_SUCCESS,
  UPDATE_PICTURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  SET_NEW_PASSWORD,
} from './constants';

export const loginUser = (email, password, history) => ({
  type: LOGIN_USER,
  payload: { email, password, history },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const registerUser = (user) => ({
  type: REGISTER_USER,
  payload: { user },
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  // payload: { history },
});

export const autoLogin = (auth) => ({
  type: AUTO_LOGIN,
  payload: auth,
});
export const checkActiveUser = ({ uid, mid, token }) => ({
  type: RELOAD_PAGE_AUTH,
  payload: { uid, mid, token },
});
export const checkActiveUserSuccess = (user) => ({
  type: RELOAD_PAGE_AUTH_SUCCESS,
  payload: user,
});

// FUNCTIONS WHEN "FORGOT PASSWORD IS CLICKED"
export const sendEmailForPasswordRecovery = (email) => ({
  type: PASSWORD_RECOVERY,
  payload: email,
});
export const setNewPassword = ({ uid, newPassword }) => ({
  type: SET_NEW_PASSWORD,
  payload: { uid, newPassword },
});

export const changePassword = (token, password) => ({
  type: CHANGE_PASSWORD,
  payload: { token, password },
});
export const changePasswordSuccess = (changePasswordStatus) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: changePasswordStatus,
});

export const updatePicture = (file) => ({
  type: UPDATE_PICTURE,
  payload: file,
});
export const updatePictureSuccess = (updatePictureSuccess) => ({
  type: UPDATE_PICTURE_SUCCESS,
  payload: updatePictureSuccess,
});

// actions for update user info
export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});
export const updateUserSuccess = ({ success, user }) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { success, user },
});

export const successMessage = (message) => ({
  type: SUCCESS_MESSAGE,
  payload: message,
});
export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});
export const apiError = (error) => ({
  type: API_FAILED,
  payload: error,
});
