export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';

export const RELOAD_PAGE_AUTH = 'RELOAD_PAGE_AUTH';
export const RELOAD_PAGE_AUTH_SUCCESS = 'RELOAD_PAGE_AUTH_SUCCESS';

export const AUTO_LOGIN = 'AUTO_LOGIN';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';

export const PASSWORD_RECOVERY = 'ASK_PASSWORD_RECOVERY';
export const PASSWORD_RECOVERY_SUCCESS = 'ASK_PASSWORD_RECOVERY_SUCCESS';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const UPDATE_PICTURE = 'UPDATE_PICTURE';
export const UPDATE_PICTURE_SUCCESS = 'UPDATE_PICTURE_SUCCESS';

// export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';
// export const REQUEST_PAYMENT_SUCCESS = 'REQUEST_PAYMENT_SUCCESS';

export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const API_FAILED = 'AUTH_API_FAILED';
