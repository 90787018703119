import axios from 'axios';
// import config from './../config';

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

/**
 * Sets the headers depending if auth is needed
 * @param {*} token
 */
const setHeaders = (token, json = true) => {
  const Accept = { Accept: 'application/json', 'Content-Type': 'application/json' };
  const Authorization = { Authorization: 'Bearer ' + token };
  let headers =
    json && token
      ? { ...Accept, ...Authorization }
      : !json && token
      ? { ...Authorization }
      : json && !token
      ? { ...Accept }
      : !json && !token && {};
  // token && console.log('headers: ', headers);
  return headers;
};

const baseUrl = axios.defaults.baseURL;

class APIClient {
  /**
   * post given json to url
   */
  post = async ({ url, data = null, token = null, json = false }) => {
    try {
      // console.log({ url, data, token });
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'POST',
        headers: setHeaders(token),
        body: JSON.stringify(data),
        credentials: 'include',
      });
      if (json) {
        const json = await response.json();
        // console.log({ code, json });
        return { response, json };
      } else {
        return { response };
      }
    } catch (error) {
      console.error(error);
    }
  };

  postForm = async ({ url, data, token = null, json = false }) => {
    try {
      // console.log({ url, data, token, json });
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'POST',
        body: data,
        headers: setHeaders(token, false),
        credentials: 'include',
      });
      if (json) {
        const json = await response.json();
        return { response, json };
      } else return { response };
    } catch (error) {
      console.error('error on post formData: ', error);
    }
  };

  putForm = async ({ url, data, token = null, json = false }) => {
    try {
      // console.log({ url, data, token, json });
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'PUT',
        body: data,
        headers: setHeaders(token, false),
        credentials: 'include',
      });
      if (json) {
        const json = await response.json();
        return { response, json };
      } else return { response };
    } catch (error) {
      console.error('error on post formData: ', error);
    }
  };

  /* update method with authorization */
  put = async ({ url, data, token = null }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'PUT',
        headers: setHeaders(token),
        body: JSON.stringify(data),
        credentials: 'include',
      });
      return response;
    } catch (error) {
      console.error('error en update: ', error);
    }
  };

  /* fetches json from server */
  get = async ({ url, token = null }) => {
    try {
      // console.log({ url, token });
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'GET',
        headers: setHeaders(token),
        credentials: 'include',
      });
      const json = await response.json();
      return { response, json };
    } catch (error) {
      console.error('error on get method: ', error);
    }
  };

  /**
   * Delete
   */
  delete = async ({ url, token = null }) => {
    try {
      // console.log({ url, token, headers: setHeaders(token) });
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'DELETE',
        headers: setHeaders(token),
      });
      // const json = await response.json();
      return { response };
    } catch (error) {
      console.error('error on get method: ', error);
    }
  };

  uploadImage = async ({ url, data, token = null }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'PUT',
        body: data,
        headers: setHeaders(token, false),
        credentials: 'include',
      });
      // console.log(response);
      const string = await response.text();
      // console.log(string);
      return { response, string };
    } catch (error) {
      console.error('error on upload image: ', error);
    }
  };
}

export { APIClient };
