import CryptoJS from 'crypto-js';
import { APIClient } from './apiClient';
import Cookies from 'universal-cookie';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const get = new APIClient().get;
const cookies = new Cookies();

const encryptSecretKey = process.env.REACT_APP_ENCRYPTED_KEY;
const IMAGE_MERCHANT = process.env.REACT_APP_CHAT_FILE_PATH;

const encryptData = (dataToEncrypt) => {
  const keySize = 256;
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
    keySize: keySize / 32,
    iterations: 100,
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
  return result.replaceAll('+', ' ');
};

const decryptData = (dataToDecrypt) => {
  const key = CryptoJS.enc.Utf8.parse(encryptSecretKey);
  const iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
  const decrypted = CryptoJS.AES.decrypt(dataToDecrypt, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const getArrayList = async ({ value, path, param }) => {
  const array = await get({ url: `/miscellaneous${path}?${param}=${value}` });
  return array.json;
};

const getList = async (path, value, string) => {
  const list = await get({ url: `/miscellaneous${path}=${value}` });
  const listNewKeys = changeKeys(list.json, string);
  return listNewKeys;
};

const getSelectedOption = (array, i) => {
  const res = array.find((item) => item.value === i);
  return res;
  // if (state === 'country') {
  //   setCountrySelected(res);
  // } else if (state === 'state') {
  //   setStateSelected(res);
  // }
};

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const changeKeys = (array, string) => {
  try {
    if (array !== undefined || (Array.isArray(array) && array.length)) {
      var finalArray = [];
      finalArray = array.map((item) => {
        item = renameKey(item, 'name', 'label');
        item = renameKey(item, string, 'value');
        return item;
      });
      return finalArray;
    }
  } catch (error) {
    console.log(error);
  }
};

const setLocalStorage = (name, item) => {
  localStorage.setItem(name, JSON.stringify(item));
};

const getLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
};

const jsonToFormData = (data) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

const validateFileSize = (file) => {
  if (file != null) {
    if (file.size / 1024 / 1024 > 5) {
      return false;
    } else {
      return true;
    }
  }
};

const getAuthData = () => {
  const token = cookies.get('Z2B2_MERCHANT_TOKEN');
  const uid = JSON.parse(localStorage.getItem('Z2B2_MERCHANT_UID'));
  return { token, uid: uid.uid, mid: uid.mid };
};

// regex for passwords
const passwordRules = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

// create a history prop to redirect from any file
const history = createBrowserHistory();

// call the endpoint to bring all the chats and contacts
const getUsersAndContacts = async ({ mid, token, take = 20, skip = 0 }) => {
  const users = await get({
    url: `/Chat/Get?merchantId=${mid}&take=${take}&skip=${skip}`,
    token,
  });
  // console.log(users.json);
  const contacts = await get({
    url: `/Group/CountContacts?merchantId=${mid}`,
    token,
  });
  return { users: users.json, contacts: contacts.json };
};

const loadMessages = async ({ chatId, token, take = 20, skip = 0 }) => {
  // console.log({ take, skip });
  const uid = JSON.parse(localStorage.getItem('Z2B2_MERCHANT_UID'));

  const merchant = await get({
      url: `/Test/Decrypt?token=${uid.mid}`,
      token: token,
    });

  const requestMessages = await get({
    url: `/Chat/GetMessages?chatId=${chatId}&take=${take}&skip=${skip}`,
    token,
  });

  requestMessages.json.forEach(chat =>{
    chat.imageMessage = chat.imageMessage ? IMAGE_MERCHANT + merchant.json +'/' + chat.imageMessage : null
  });
  
  return requestMessages;
};

// sort by lastUpdate the array of chats
const getGroupsAndSortedChats = async (users) => {
  const sorted = await sortedByDate(users);
  // filter conversations array to find groups
  const groups = await users.filter((item) => item.isGroup);
  return { sorted, groups };
};

// sort chats by date
const sortedByDate = (array) => {
  // console.log('not sort: ', array);
  const response = array.sort((a, b) => {
    // create a new Date Object with the dates strings
    const aDate = new Date(a.lastUpdated);
    const bDate = new Date(b.lastUpdated);
    // compare to get the newest chat first
    return bDate.getTime() - aDate.getTime();
  });
  // console.log('chats sorted: ', response);
  return response;
};

// look for the chat that correspond with the given chatId
const findChatAndIndex = async (chats, chatId) => {
  const chat = chats.filter((chat) => chat.chatId === chatId)[0]; // find which chat correspond the new messsage
  const index = chats.findIndex((chat) => chat.chatId === chatId); // find the chat index
  if (chat && index >= 0) {
    const chatMessages = Object.assign([], chat.messages); //copying messages array from selected chat
    return { found: true, chat, index, chatMessages };
  } else {
    const auth = getAuthData();
    const { mid, token } = auth;
    const newChatArriving = await getUsersAndContacts({ mid, token, take: 1 });
    const getMessagesFromNewChat = await loadMessages({ chatId, mid, token });
    const newChat = {
      ...newChatArriving.users[0],
      messages: getMessagesFromNewChat.json,
      loaded: true,
    };
    return { found: false, newChat };
  }
};

// check if the message (sent/received) belongs to an existing chat
const addMessageOrRetrieveChat = (chatAndIndex, messageRequest) => {
  if (chatAndIndex.found) {
    // console.log('chat found');
    const { chat, index, chatMessages } = chatAndIndex;

    chatMessages.push(messageRequest); //adding new message to array
    chat.messages = chatMessages; //adding new message to the real chat array

    const newChat = { ...chat, messages: chatMessages, lastUpdated: messageRequest.time }; // creating the new chat object and updating the date
    // const newState = yield addNewChatToCurrentArrayOfChats({ newChat, index, chats: users });
    // console.log(newState);
    return { newChat, index };
  } else {
    // console.log('chat not found');
    const { newChat } = chatAndIndex;
    // console.log(newChat);
    return { newChat, index: -1 };
  }
};

// add the new chat with the new message(s) added to the chats array, sort it and find the new index
const addNewChatToCurrentArrayOfChats = ({ newChat, index, chats }) => {
  // console.log({ newChat, index, chats });
  if (index >= 0) {
    //if index exists replace che chat in the index position
    chats[index] = newChat;
  } else {
    //if index doesn't exists, it's a new chat and add it to the chats array
    chats.push(newChat);
  }
  const sorted = sortedByDate(chats); //sorting the new array of chats
  const newIndex = sorted.findIndex((chat) => chat.chatId === newChat.chatId); //find the new index of the new chat inside the new sorted array
  // console.log({ newChat, sorted, index, newIndex });
  return { sorted, newIndex };
};

// function to add message when a new message is sent or received
const addNewMessageToChats = (message, state) => {
  // console.log({ message, state });
  const { merchantContactId, messageRequest } = message; // destructuring message
  const chatAndIndex = findChatAndIndex(state, merchantContactId);
  if (!chatAndIndex.found) {
    return chatAndIndex.then((r) => {
      // console.log(r);
      return addNewChatToCurrentArrayOfChats({ chats: state, newChat: r.newChat });
    });
    // return;
  } else {
    const { chat, index, chatMessages } = chatAndIndex;
    chatMessages.push(messageRequest); //adding new message to array
    const newChat = { ...chat, messages: chatMessages, lastUpdated: messageRequest.time }; // creating the new chat object and updating the date
    return addNewChatToCurrentArrayOfChats({ chats: state, index, newChat });
  }
};

// function called from reducer when user clicks "load more" button or select a chat
const addMessagesToChat = async ({ messages, chatId, users }) => {
  // console.log({ messages, users, chatId });
  const chatAndIndex = await findChatAndIndex(users, chatId);
  const { chat, index, chatMessages } = chatAndIndex;
  messages.forEach((message) => {
    // type === 'push' ? chatMessages.push(message) : chatMessages.unshift(message); //adding new messages to array
    chatMessages.unshift(message);
    // console.log(`${message.userType} - ${message.time}`);
  });
  const chatSelected = {
    ...chat,
    messages: chatMessages,
    lastUpdated: messages[messages.length - 1].time,
    loaded: true,
  }; // creating the new chat object and updating the date
  const copyUsers =  Object.assign([], users);
  copyUsers[index] = chatSelected;

  //return addNewChatToCurrentArrayOfChats({ chats: users, index, chatSelected });
  return { chatSelected: copyUsers, index };
};

const addMoreChats = (payload, users) => {
  payload.forEach((user) => {
    users.push(user);
  });
  const sorted = sortedByDate(users);
  return sorted;
};

// add the list of members when open sidebar of single chat
const addMemberToChats = (member, state) => {
  const chat = state.filter((chat) => chat.chatId === member.chatId);
  // console.log('chat; ', chat);
  const index = state.findIndex((chat) => chat.chatId === member.chatId);
  let array = [];
  if (member.page > 1) array = chat[0].members;
  array.push(...member.members);
  //console.log("array-contact: ", array);
  const newChat = { ...chat[0], members: array };
  // console.log("nerchat: ", newChat);
  const newState = Object.assign([], state);
  newState[index] = newChat;
  // console.log("state; ", newState);
  return newState;
};

const deleteChat = (chatId, state) => {
  const indexToDelete = state.findIndex((chat) => chat.chatId === chatId); // find the chat index
  state.splice(indexToDelete, 1);
  return state;
};

export {
  encryptData,
  decryptData,
  getArrayList,
  changeKeys,
  setLocalStorage,
  getLocalStorage,
  jsonToFormData,
  getList,
  getSelectedOption,
  validateFileSize,
  getAuthData,
  passwordRules,
  history,
  getUsersAndContacts,
  loadMessages,
  getGroupsAndSortedChats,
  sortedByDate,
  findChatAndIndex,
  addMessageOrRetrieveChat,
  addNewChatToCurrentArrayOfChats,
  addNewMessageToChats,
  deleteChat,
  addMemberToChats,
  addMessagesToChat,
  addMoreChats,
};
