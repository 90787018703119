import React from 'react';
import { withRouter } from 'react-router-dom';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { clearMessages } from '../../redux/actions';

import { connect } from 'react-redux';

//Import Components
import LeftSidebarMenu from './LeftSidebarMenu';

const Index = ({ error, success, location, children }) => {
  const [alert, setAlert] = React.useState({ open: false });

  //function for capital first letter of current page pathname
  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  React.useEffect(() => {
    let currentage = capitalizeFirstLetter(location.pathname);
    //set document title according to page path name
    document.title = currentage + ' | Z2B2 - Merchant';
  }, []);

  React.useEffect(() => {
    if (error) {
      setAlert({ open: !alert.open, message: error });
    } else if (success) {
      setAlert({ open: !alert.open, message: success });
    }
  }, [error, success]);

  const closeAlert = () => {
    setAlert({ open: !alert.open });
    console.log('alert closed');
    clearMessages();
    console.log('clear messages done');
  };

  return (
    <React.Fragment>
      <Toast
        isOpen={alert.open}
        id='toast-alert'
        className='position-absolute m-4 rounded'
        style={{ zIndex: '8000', right: '0' }}
      >
        <ToastHeader toggle={closeAlert} icon={success ? 'success' : error && 'danger'}>
          {success ? ' Well done' : error && ' Something went wrong.'}
        </ToastHeader>
        <ToastBody>{alert.message}</ToastBody>
      </Toast>

      <div className='layout-wrapper d-lg-flex'>
        {/* left sidebar menu */}
        <LeftSidebarMenu />
        {/* render page content */}
        {children}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, success } = state.Auth;
  return { error, success };
};

export default withRouter(connect(mapStateToProps, { clearMessages })(Index));
