// export const RELOAD_PAGE_CHAT = 'RELOAD_PAGE_CHAT';
export const ACTIVE_USER = 'ACTIVE_USER';

export const ADD_LOGGED_USER = 'ADD_LOGGED_USER';

export const CHAT_ERROR = 'CHAT_ERROR';

export const CHAT_USER = 'CHAT_USER';

export const CLEAR_MESSAGES_CHAT = 'CLEAR_MESSAGES_CHAT';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const DELETE_CHAT = 'DELETE_CHAT';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';

export const FULL_USER = 'FULL_USER';

export const GET_CHAT_DATA_ON_LOGIN = 'GET_CHAT_DATA_ON_LOGIN';
export const GET_CHAT_DATA_ON_RELOAD = 'GET_CHAT_DATA_ON_RELOAD';

export const OPEN_SOCKET_CONNECTION = 'OPEN_SOCKET_CONNECTION';

export const GET_MESSAGES_BY_CHAT = 'GET_MESSAGES_BY_CHAT';
export const GET_MESSAGES_BY_CHAT_SUCCESS = '  GET_MESSAGES_BY_CHAT_SUCCESS';

export const NEW_MESSAGE_INCOMING = 'NEW_MESSAGE_INCOMING';
export const FINDING_NEW_MESSAGE_CHAT = 'FINDING_NEW_MESSAGE_CHAT';
export const NEW_MESSAGE_SUCCESS = 'NEW_MESSAGE_SUCCESS';

export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const LOAD_MORE_MESSAGES_SUCCESS = 'LOAD_MORE_MESSAGES_SUCCESS';

export const LOAD_MORE_CHATS = 'LOAD_MORE_CHATS';
export const LOAD_MORE_CHATS_SUCCESS = 'LOAD_MORE_CHATS_SUCCESS';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const START_BROADCAST = 'START_BROADCAST';
export const START_BROADCAST_RESPONSE = 'START_BROADCAST_RESPONSE';

export const ADD_GROUPS_MEMBERS = 'ADD_GROUPS_MEMBERS';
export const ADD_GROUPS_MEMBERS_RESPONSE = 'ADD_GROUPS_MEMBERS_RESPONSE';

export const GET_MEMBERS_LIST = 'GET_MEMBERS_LIST';
export const GET_MEMBERS_LIST_SUCCESS = 'GET_MEMBERS_LIST_SUCCESS';
