import {
  // RELOAD_PAGE_CHAT,
  ACTIVE_USER,
  ADD_GROUPS_MEMBERS_RESPONSE,
  ADD_GROUPS_MEMBERS,
  ADD_LOGGED_USER,
  CHAT_ERROR,
  CHAT_USER,
  CLEAR_MESSAGES_CHAT,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT,
  FULL_USER,
  GET_CHAT_DATA_ON_LOGIN,
  GET_CHAT_DATA_ON_RELOAD,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST,
  GET_MESSAGES_BY_CHAT_SUCCESS,
  GET_MESSAGES_BY_CHAT,
  OPEN_SOCKET_CONNECTION,
  LOAD_MORE_CHATS_SUCCESS,
  LOAD_MORE_CHATS,
  LOAD_MORE_MESSAGES_SUCCESS,
  LOAD_MORE_MESSAGES,
  NEW_MESSAGE_INCOMING,
  FINDING_NEW_MESSAGE_CHAT,
  NEW_MESSAGE_SUCCESS,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE,
  START_BROADCAST_RESPONSE,
  START_BROADCAST,
} from './constants';

export const chatUser = () => ({
  type: CHAT_USER,
});

export const activeUser = (userId) => ({
  type: ACTIVE_USER,
  payload: userId,
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser,
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData,
});

export const createGroup = ({ values, members, addMembers, skip }) => ({
  type: CREATE_GROUP,
  payload: { values, members, addMembers, skip },
});
export const createGroupSuccess = ({ groups, success, users, contacts }) => ({
  type: CREATE_GROUP_SUCCESS,
  payload: { groups, success, users, contacts },
});

// start new broadcast
export const startBroadcast = ({ values, channels }) => ({
  type: START_BROADCAST,
  payload: { values, channels },
});
export const startBroadcastResponse = (message) => ({
  type: START_BROADCAST_RESPONSE,
  payload: message,
});

// action to get list of contacts/chats/etc...
export const getDataOnLogin = ({ users, groups, contacts }) => ({
  type: GET_CHAT_DATA_ON_LOGIN,
  payload: {
    users,
    contacts,
    groups,
  },
});

export const getDataOnReload = ({ users, groups, contacts }) => ({
  type: GET_CHAT_DATA_ON_RELOAD,
  payload: {
    users,
    contacts,
    groups,
  },
});

// actions to activate send messages
export const sendMessage = ({ text, type, chatId, isGroup, users }) => ({
  type: SEND_MESSAGE,
  payload: { text, type, chatId, isGroup, users },
});
export const sendMessageSuccess = ({ messageRequest, merchantContactId }) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { messageRequest, merchantContactId },
});

// actions to delete chat
export const deleteChat = ({ chatId, isGroup }) => ({
  type: DELETE_CHAT,
  payload: { chatId, isGroup },
});
export const deleteChatSuccess = ({ message, chatId }) => ({
  type: DELETE_CHAT_SUCCESS,
  payload: { message, chatId },
});

// actions to get messages
export const openSocketConnection = (token) => ({
  type: OPEN_SOCKET_CONNECTION,
  payload: token,
});
export const getMessagesByChat = ({ users, chatId, skip, isGroup }) => ({
  type: GET_MESSAGES_BY_CHAT,
  payload: { users, chatId, skip, isGroup },
});
export const getMessagesByChatSuccess = ({ chatSelected, index }) => ({
  type: GET_MESSAGES_BY_CHAT_SUCCESS,
  payload: { chatSelected, index },
});

export const newMessageIncoming = ({ merchantContactId, messageRequest }) => ({
  type: NEW_MESSAGE_INCOMING,
  payload: { merchantContactId, messageRequest },
});
export const findingNewMessageChat = ({ users, incomingMessage }) => ({
  type: FINDING_NEW_MESSAGE_CHAT,
  payload: { users, incomingMessage },
});
export const newMessageSuccess = ({ newChat, index }) => ({
  type: NEW_MESSAGE_SUCCESS,
  payload: { newChat, index },
});

export const loadMoreMessages = ({ chatId, skip, users }) => ({
  type: LOAD_MORE_MESSAGES,
  payload: { chatId, skip, users },
});
export const loadMoreMessagesSuccess = ({ active_user, users }) => ({
  type: LOAD_MORE_MESSAGES_SUCCESS,
  payload: { active_user, users },
});

export const loadMoreChats = (skip) => ({
  type: LOAD_MORE_CHATS,
  payload: skip,
});
export const loadMoreChatsSuccess = (users) => ({
  type: LOAD_MORE_CHATS_SUCCESS,
  payload: users,
});

// add members to an existing group
export const addGroupMembers = ({ group, members, addMembers }) => ({
  type: ADD_GROUPS_MEMBERS,
  payload: { group, members, addMembers },
});
//success add member to an existing group
export const addGroupMembersResponse= ({group, members, success, page}) =>({
  type: ADD_GROUPS_MEMBERS_RESPONSE,
  payload: {group, members, success, page}
});

// enlist group members
export const getMembersList = ({ chatId, page }) => ({
  type: GET_MEMBERS_LIST,
  payload: { chatId, page },
});
export const getMembersListSuccess = (members, chatId, page) => ({
  type: GET_MEMBERS_LIST_SUCCESS,
  payload: { members, chatId, page },
});

// set error
export const chatError = (error) => ({
  type: CHAT_ERROR,
  payload: error,
});
export const clearMessagesChat = () => ({
  type: CLEAR_MESSAGES_CHAT,
});
