import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIClient } from '../../helpers/apiClient';
// import { encryptData } from '../../helpers/customFunctions';
import Cookies from 'universal-cookie';
import {
  encryptData,
  getAuthData,
  sortedByDate,
  getUsersAndContacts,
} from '../../helpers/customFunctions';
import moment from 'moment';

import {
  AUTO_LOGIN,
  CHANGE_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY,
  RELOAD_PAGE_AUTH,
  SET_NEW_PASSWORD,
  UPDATE_PICTURE,
  UPDATE_USER,
} from './constants';

import {
  loginUserSuccess,
  checkActiveUserSuccess,
  updateUserSuccess,
  apiError,
  successMessage,
  updatePictureSuccess,
} from './actions';

import { getDataOnLogin, getDataOnReload, openSocketConnection } from '../chat/actions';

/**
 * Sets the session
 * @param {*} user
 */

const cookies = new Cookies();
const get = new APIClient().get;
const post = new APIClient().post;
const postForm = new APIClient().postForm;
const update = new APIClient().put;
const updateImage = new APIClient().uploadImage;

// IMAGE PATH URL
const IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;

/**
 * USEFUL FUNCTIONS
 */

const getGroupsAndSortedChats = async (users) => {
  // console.log('users to sort: ', users);
  const sorted = await sortedByDate(users);
  // filter conversations array to find groups
  const groups = await users.filter((item) => item.isGroup);
  return { sorted, groups };
};

const replaceImagesURL = async (data) => {
  // console.log('replace image: ', data);
  return await {
    ...data,
    merchantProfilePicture: data.merchantProfilePicture
      ? IMAGE_PATH + data.merchantProfilePicture
      : null,
    profilePicture: data.profilePicture ? IMAGE_PATH + data.profilePicture : null,
  };
};

// const getUsersAndContacts = async ({ mid, token, take = 20, skip = 0 }) => {
//   const users = await get({
//     url: `/Chat/Get?merchantId=${mid}&take=${take}&skip=${skip}`,
//     token,
//   });
//   // console.log('users: ', users);
//   const contacts = await get({
//     url: `/Group/CountContacts?merchantId=${mid}`,
//     token,
//   });
//   return { users: users.json, contacts: contacts.json };
// };

const getMerchantDetails = async ({ uid, token }) => {
  return await get({
    url: `/Merchant/GetMerchantDetails?userId=${uid}`,
    token: token,
  });
};

function* closeSession() {
  // console.log('session closed due to an error');
  yield call(() => {
    localStorage.removeItem('Z2B2_MERCHANT_UID');
    cookies.remove('Z2B2_MERCHANT_TOKEN');
    // history.push('/login');
    window.location.reload();
  });
}

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload: { email, password, history } }) {
  try {
    const login = yield call(post, {
      url: '/Auth/Login',
      data: { email, password },
      json: true,
    });

    if (login.response.status === 200) {
      const { token, userId } = login.json;

      const encryptedToken = encryptData(token);
      const encryptedUid = encryptData(userId);

      console.log(login);

      const merchantDetails = yield getMerchantDetails({
        uid: encryptedUid,
        token: encryptedToken,
      });

      if (merchantDetails.response.status === 200) {
        console.log(merchantDetails);
        const encryptedMID = encryptData(merchantDetails.json.merchantId);

        const usersAndContacts = yield getUsersAndContacts({
          mid: encryptedMID,
          token: encryptedToken,
        });
        const merchant = yield replaceImagesURL(merchantDetails.json);
        const chats = yield getGroupsAndSortedChats(usersAndContacts.users);

        yield put(loginUserSuccess(merchant));
        yield put(
          getDataOnLogin({
            users: chats.sorted,
            groups: chats.groups,
            contacts: usersAndContacts.contacts,
          })
        );

        yield put(openSocketConnection(encryptedToken));

        cookies.set('Z2B2_MERCHANT_TOKEN', encryptedToken, {
          path: '/',
          // expires: date.setDate(date.getDate() + 1),
          // domain: '.Z2B2.com',
          // secure: true,
        });
        localStorage.setItem(
          'Z2B2_MERCHANT_UID',
          JSON.stringify({ uid: encryptedUid, mid: encryptedMID })
        );

        history.push('/dashboard');
      } else {
        yield put(apiError('Error retrieving merchant information.'));
      }
    } else {
      yield put(apiError('Error retrieving user information.'));
    }
  } catch (error) {
    // yield put(apiError(error));
    console.log('error on login: ', error);
  }
}

/**
 * Retrieve the user info when reload
 * @param {*} payload - uid token
 */
function* autoLogin({ payload: auth }) {
  try {
    console.log('autologin: ', auth);
    const values = JSON.parse(auth);
    const uid = values[0];
    const token = values[1];
    const merchantDetails = yield getMerchantDetails({ uid, token });
    console.log(merchantDetails);
    if (merchantDetails.response.status === 200) {
      const encryptedMID = encryptData(merchantDetails.json.merchantId);
      cookies.set('Z2B2_MERCHANT_TOKEN', token, {
        path: '/',
        // expires: date.setDate(date.getDate() + 1),
        // domain: '.Z2B2.com',
        // secure: true,
      });
      localStorage.setItem('Z2B2_MERCHANT_UID', JSON.stringify({ uid, mid: encryptedMID }));
      window.location.reload();
    } else {
      yield put(apiError('Error retrieving merchant information.'));
      yield closeSession();
    }
  } catch (error) {
    console.error('error when reload page: ', error);
  }
}

/**
 * Retrieve the user info when reload
 * @param {*} payload - uid token
 */
function* checkActiveUser({ payload: { uid, mid, token } }) {
  try {
    // console.log('entre al CheckActiveUser: ', { uid, mid, token });
    const merchantDetails = yield getMerchantDetails({ uid, token });

    // console.log(merchantDetails);

    if (merchantDetails.response.status === 200) {
      const usersAndContacts = yield getUsersAndContacts({ mid, token });
      const merchant = yield replaceImagesURL(merchantDetails.json);
      const chats = yield getGroupsAndSortedChats(usersAndContacts.users);
      // console.log({ usersAndContacts, merchant, chats });
      yield put(
        getDataOnLogin({
          users: chats.sorted,
          groups: chats.groups,
          contacts: usersAndContacts.contacts,
        })
      );
      yield put(checkActiveUserSuccess(merchant));
      yield put(openSocketConnection(token));
    } else {
      yield put(apiError('Error retrieving merchant information.'));
      yield closeSession();
    }
  } catch (error) {
    console.log('error when reload page: ', error);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout() {
  try {
    yield call(() => {
      localStorage.removeItem('Z2B2_MERCHANT_UID');
      cookies.remove('Z2B2_MERCHANT_TOKEN');
      // history.push('/login');
      window.location.reload();
    });
  } catch (error) {}
}

/**
 * recover password process (1. send email for recover password, 2. set the new password)
 */
function* sendEmailForPasswordRecovery({ payload: email }) {
  try {
    // console.log('send email for password recovery: ', email);

    yield call(post, { url: '/Account/ForgotPassword', data: email });

    // console.log(sendEmail);

    // if (sendEmail.response.status === 200) {
    yield put(successMessage(`If this user exists, you'll get an email, check your inbox.`));
    // } else {
    // yield put(apiError('The user email do not exist, try again.'));
    // }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* setNewPassword({ payload: { uid, newPassword } }) {
  try {
    // console.log('set new password: ', { uid, newPassword });

    const forgetPassword = yield call(update, {
      url: `/Account/NewPassword?user=${uid}&password=${newPassword}`,
    });

    // console.log(forgetPassword);
    if (forgetPassword.status === 200) {
      yield put(successMessage(`Password successfully changed, now you can log in.`));
    } else {
      yield put(apiError(`We couldn't change the password, please try again.`));
    }
  } catch (error) {}
}
/**
 * change password
 */
function* changePassword({ payload: { token, password } }) {
  try {
    var getUrl = window.location;
    var baseUrl = getUrl.origin;
    const response = yield call(update, `/Account/NewPassword?us=${token}&pwd=${password}`);

    if (response === true) {
      yield put(successMessage('Password has been changed successfully.'));
      //redirect
      window.setTimeout(function () {
        window.location.href = baseUrl + '/login';
      }, 1500);
    } else {
      yield put(apiError('Error changing password.'));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update user info
 * @param {*} payload - data
 */
function* updateUser({ payload: data }) {
  try {
    // console.log('entre al update user: ', data);
    const { name, lastName, driverLicence, documentPath, address, country, state, city, zip } =
      data;

    const user = {
      name,
      lastName,
      driverLicence,
      documentPath,
      address,
      countryId: country.id,
      stateId: state.id,
      city,
      zip,
    };

    const uid = JSON.parse(localStorage.getItem('Z2B2_MERCHANT_UID'));
    const token = cookies.get('Z2B2_MERCHANT_TOKEN');

    const updateUser = yield call(
      update({ url: `/Account/UpdateUser?userId=${uid.uid}`, data: user, token })
    );
    if (updateUser.status === 200) {
      const merchantDetails = yield call(
        get,
        `/Merchant/GetMerchantDetails?userId=${uid.uid}`,
        uid.token
      );

      const user = {
        ...merchantDetails.json,
        merchantProfilePicture: IMAGE_PATH + merchantDetails.json.merchantProfilePicture,
        profilePicture: IMAGE_PATH + merchantDetails.json.profilePicture,
      };
      yield put(updateUserSuccess({ success: 'User Updated Successfully.', user }));
    } else {
      yield put(apiError('Ups, there was an error, please try again.'));
    }
  } catch (error) {
    console.error('error when update: ', error);
  }
}

function* updatePicture({ payload: file }) {
  try {
    const auth = getAuthData();
    const { uid, token } = auth;

    const data = new FormData();
    data.append('file', file);

    const update = yield call(updateImage, {
      url: `/Account/UserPhoto?userId=${uid}`,
      data,
      token,
    });

    if (update.response.status === 200) {
      yield put(successMessage('Image updated successfully.'));
      yield put(updatePictureSuccess(update.string));
    } else {
      yield put(apiError(update.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchAutoLogin() {
  yield takeEvery(AUTO_LOGIN, autoLogin);
}

export function* watchCheckActiveUser() {
  yield takeEvery(RELOAD_PAGE_AUTH, checkActiveUser);
}

export function* watchSendEmailForPasswordRecovery() {
  yield takeEvery(PASSWORD_RECOVERY, sendEmailForPasswordRecovery);
}

export function* watchSetNewPassword() {
  yield takeEvery(SET_NEW_PASSWORD, setNewPassword);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchUpdatePicture() {
  yield takeEvery(UPDATE_PICTURE, updatePicture);
}

function* authSaga() {
  yield all([
    fork(watchAutoLogin),
    fork(watchChangePassword),
    fork(watchCheckActiveUser),
    fork(watchSendEmailForPasswordRecovery),
    fork(watchSetNewPassword),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchUpdatePicture),
    fork(watchUpdateUser),
  ]);
}

export default authSaga;
