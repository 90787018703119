import React from 'react';
import { Redirect } from 'react-router-dom';

// lazy load all the views
const Dashboard = React.lazy(() => import('../pages/dashboard/index'));
const StarterPage = React.lazy(() => import('../pages/StarterPage/index'));

// auth
const Login = React.lazy(() => import('../pages/Auth/Login'));
const Logout = React.lazy(() => import('../pages/Auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/Auth/ForgetPassword'));

// declare all routes
const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/pages-starter', component: StarterPage },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
  },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forget-password', component: ForgetPassword },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
