import React from 'react';
import Routes from './routes/';
import { connect } from 'react-redux';
import { checkActiveUser, autoLogin } from './redux/actions';
import Cookies from 'universal-cookie';
//Import Scss
import './assets/scss/themes.scss';
const cookies = new Cookies();

function App({ user, checkActiveUser, autoLogin }) {
  React.useEffect(() => {
    const token = cookies.get('Z2B2_MERCHANT_TOKEN');
    const uid = JSON.parse(localStorage.getItem('Z2B2_MERCHANT_UID'));
    const url = window.location.search;
    const urlParam = new URLSearchParams(url);
    const auth = urlParam.get('auth');
    // if cookie and localstorage, get the user info, if get params for autologin, run autologin function

    token && uid && !user
      ? checkActiveUser({ token, uid: uid.uid, mid: uid.mid })
      : auth && autoLogin(auth);
  }, []);

  return <Routes />;
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};

export default connect(mapStateToProps, { checkActiveUser, autoLogin })(App);
