import {
  API_FAILED,
  AUTO_LOGIN_SUCCESS,
  AUTO_LOGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CLEAR_MESSAGES,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  RELOAD_PAGE_AUTH_SUCCESS,
  RELOAD_PAGE_AUTH,
  SET_NEW_PASSWORD,
  SUCCESS_MESSAGE,
  UPDATE_PICTURE_SUCCESS,
  UPDATE_PICTURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case LOGOUT_USER:
      return { ...state, user: null };

    case AUTO_LOGIN:
      return { ...state, loading: true };
    case RELOAD_PAGE_AUTH:
      return { ...state, loading: true };
    case RELOAD_PAGE_AUTH_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case PASSWORD_RECOVERY:
      return { ...state, loading: true };

    case SET_NEW_PASSWORD:
      return { ...state, loading: true };

    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordStatus: action.payload,
        loading: false,
        error: null,
      };

    case UPDATE_PICTURE:
      return { ...state, loading: true };
    case UPDATE_PICTURE_SUCCESS:
      const newUser = { ...state.user, profilePicture: action.payload };
      return {
        ...state,
        user: newUser,
        loading: false,
        error: null,
      };

    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        user: action.payload.user,
        loading: false,
        error: null,
      };

    // case REQUEST_PAYMENT:
    //   return { ...state, loading: true };
    // case REQUEST_PAYMENT_SUCCESS:
    //   return { ...state, loading: false, success: action.payload.success, error: null };

    case SUCCESS_MESSAGE:
      return { ...state, loading: false, success: action.payload };
    case CLEAR_MESSAGES:
      delete state.error;
      delete state.success;
      return { ...state, loading: false };
    case API_FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default Auth;
